/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout/index'
import SectionCursos from '../../components/SectionCursos/sectionCursos'
import './spani-qualifica.scss'
import { GatsbyImage } from "gatsby-plugin-image";
import setaVoltar from '../../images/icons/post-blog/seta-voltar.svg'
import axios from 'axios'
import InputMask from 'react-input-mask'
import Helmet from 'react-helmet'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'

const sendEvent = (action, label = 'none') => {
  // eslint-disable-next-line no-undef
  if (typeof window !== 'undefined') {
    if (!window.dataLayer) {
      window.dataLayer = []
    }
    window.dataLayer.push({
      event: 'contato',
      eventAction: action,
      eventLabel: label
    })
  }
}

function sendSpaniQualifica () {
  // eslint-disable-next-line no-undef
  if (typeof window !== 'undefined') {
    window.dataLayer.push({
      event: 'qualifica_click'
    })
  }
}

function getNomeMes (data) {
  const monthNames = ['janeiro', 'fevereiro', 'marco', 'abril', 'maio', 'junho',
    'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro']
  const d = new Date(data)
  return monthNames[d.getMonth()]
}

const templateCursos = ({ data, pageContext }) => {
  const breadC = {
    visible: true,
    items: [
      { path: '/', name: 'Home' },
      { path: `/spani-qualifica/${getNomeMes(data.eventos.dataInicio)}/`, name: 'Spani Qualifica' }
    ]
  }

  useEffect(() => {
    formState.curso = data.eventos.nome
  }, [])

  function toHMS (date) {
    const data = new Date(date)
    const z = n => ('0' + n).slice(-2)
    return `${z(data.getHours(-3))}:${z(data.getMinutes())}`
  }

  function formattedDate (d) {
    let month = String(d.getMonth() + 1)
    let day = String(d.getDate())
    const year = String(d.getFullYear())

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return `${day}/${month}/${year}`
  }

  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [formState, setFormState] = useState([])
  const [clickEnvio, setClickEnvio] = useState(false)

  function validaEmail () {
    return !!formState.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/);
  }

  function resetForm () {
    formState.nome = ''
    formState.email = ''
    formState.telefone = ''
  }

  function handleFormValidation () {
    setSuccessMessage(false)
    if (formState && Object.keys(formState).length < 3) {
      setErrorMessage('Preencha todos os campos')
    } else if (formState.nome.match(/[0-9]/gi)) {
      setErrorMessage('Nomes não podem conter números!')
    } else if (formState.nome.length < 1) {
      setErrorMessage('O campo nome é obrigatório!')
    } else if (!validaEmail()) {
      setErrorMessage('Digite um Email Válido!')
    } else if (formState.telefone.length < 9) {
      setErrorMessage('Telefone Inválido!')
    } else {
      return true
    }
    return false
  }

  const setInput = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  async function enviarMensagem (event) {
    event.preventDefault()
    if (handleFormValidation()) {
      setClickEnvio(true)
      const payload = {
        nome: formState.nome,
        email: formState.email,
        telefone: formState.telefone,
        curso: formState.curso,
        pagina: 'spaniqualifica'
      }
      try {
        await axios.post('https://0iynl7qv67.execute-api.us-east-1.amazonaws.com/prd/email', payload)
        setErrorMessage(false)
        setSuccessMessage(<>Cadastro realizado com sucesso!</>)
        resetForm()
        sendSpaniQualifica()
        sendEvent('form', formState.email)
        setClickEnvio(false)
      } catch (error) {
        setSuccessMessage(false)
        setErrorMessage(<>Não foi possível concluir o Cadastro. <br/> Por favor, verifique os dados e tente novamente! </>)
        setClickEnvio(false)
      }
    }
  }

  const _cursos = data.todosEventos.edges.filter(
    edge => new Date(edge.node.dataInicio).toISOString() >= new Date().toISOString() && edge.node.slug !== data.eventos.slug)
  const { contato, sobre } = data.eventos
  const date = new Date(data.eventos.dataInicio)
  const description = `Data: ${formattedDate(date)}, Local: ${data.eventos.localizacao}, Carga Horária: ${data.eventos.cargaHoraria}`
  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        return <a href={`${node.data.uri}`} target="_blank" without="true" rel="noreferrer" className="quebraLinha"><u>{children}</u></a>
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return <p className='mb-3'>{children}</p>
      }
    },
    renderMark: {
      // eslint-disable-next-line react/display-name
      [MARKS.CODE]: text => {
        return <p>{text}</p>
      }
    }
  }

  const optionsSobre = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        return <a href={`${node.data.uri}`} target="_blank" without="true" rel="noreferrer" className="quebraLinha" onClick={() => sendSpaniQualifica()}><u>{children}</u></a>
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return <p className='mb-3'>{children}</p>
      }
    },
    renderMark: {
      // eslint-disable-next-line react/display-name
      [MARKS.CODE]: text => {
        return <p>{text}</p>
      }
    }
  }

  return (
    <Layout breadCrumb={breadC}>
      <Helmet>
        <meta charSet="utf-8" />
        <html lang="pt" />
        <title>{`${data.eventos.nome} | Spani Atacadista `}</title>
        <meta name="title" content={`${data.eventos.nome} | - Spani Atacadista`} />
        <meta name="description" content={description} />
        <meta name="keywords" content={`cursos, spani, ${data.eventos.nome}`} />
        <meta name="author" content="Spani" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={`${data.eventos.nome} | - Spani Atacadista`} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://www.spani.com.br/${getNomeMes(data.eventos.dataInicio)}/${data.eventos.slug}-${pageContext.slugId}`} />
        <meta
          property="og:image"
          content={`https://www.spani.com.br${data.eventos.card.localFile.publicURL}`}
        />
        <meta property="og:description" content={description} />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Course",
              "name": "${data.eventos.nome}",
              "description": "${description}",
              "provider": {
                "@type": "Organization",
                "name": "SPANI",
                "sameAs": "https://www.spani.com.br/${getNomeMes(data.eventos.dataInicio)}/${data.eventos.slug}-${pageContext.slugId}"
              }
            }
          `}
        </script>
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-10 pt-2">
            <h1 className="font-size-15">{data.eventos.nome}</h1>
          </div>
          <div className="col-2 align-items-center justify-content-end d-flex">
            <a href="javascript:history.back()" className="d-flex align-items-center rounded px-sm-3 px-2 py-sm-1 py-2">
              <img src={setaVoltar} alt="seta de voltar" className="m-0 mr-sm-2"/>
              <span className="text-uppercase d-none d-sm-block">Voltar</span>
            </a>
          </div>
          <div className="col-lg-3 pb-2 pr-lg-1">
            <div className="cards-spaniqualifica bg-yellow p-lg-4 p-2 d-flex align-items-end">
              <h2 className="text-black mb-0 font-size15">Curso: <br/> {data.eventos.nome}</h2>
            </div>
          </div>
          <div className="col-lg-3 pb-2 pr-lg-1 pl-lg-1">
            <div className="cards-spaniqualifica bg-light-blue p-lg-4 p-2 d-flex align-items-end">
              <h2 className="text-black text-uppercase text-white mb-0 font-size15">{date.toLocaleDateString('pt-br', { day: 'numeric', month: 'long', year: 'numeric' })}</h2>
            </div>
          </div>
          <div className="col-lg-3 pb-2 pr-lg-1 pl-lg-1">
            <div className="cards-spaniqualifica bg-gray-spani p-lg-4 p-2 d-flex align-items-end">
              <h2 className="text-black mb-0 font-size15">{toHMS(data.eventos.dataInicio)}<br className="d-lg-block d-none"></br> <span className="text-gray">às</span><br className="d-lg-block d-none"></br> {toHMS(data.eventos.dataFim)}</h2>
            </div>
          </div>
          <div className="col-lg-3 pb-2 pl-lg-1">
            <div className="cards-spaniqualifica bg-gray-spani p-lg-4 p-2 d-flex align-items-end">
              <h2 className="text-black mb-0 font-size15">Carga Horária: <br className="d-lg-block d-none"></br>{data.eventos.cargaHoraria}</h2>
            </div>
          </div>
          <div className="col-lg-6 pb-lg-0 pb-2 pr-lg-1">
            <div className="cards-spaniqualifica bg-gray-spani d-flex align-items-end">
              <GatsbyImage
                image={data.eventos.imagemInterna.localFile.childImageSharp.gatsbyImageData}
                alt="imagem interna do curso"
                className="w-100 h-100" />
            </div>
          </div>
          <div className="col-lg-6 pb-lg-0 pb-2 pl-lg-1">
            <div className="cards-spaniqualifica bg-light-blue p-lg-4 p-2 d-flex align-items-end">
              <h2 className="text-white font-size15">Local: <br/> {data.eventos.localizacao}</h2>
            </div>
          </div>
          <div className="col-lg-8 mt-4">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="text-black mb-2 font-size15">Sobre o curso</h2>
                {sobre && renderRichText(sobre, optionsSobre)}
                <h2 className="text-black mb-2 mt-4 font-size15">Pré-requisitos</h2>
                <p>{data.eventos.preRequisitos.internal.content}</p>
                <h2 className="text-black mb-2 mt-4 font-size15">Valor</h2>
                <p>{data.eventos.valor}</p>
                <h2 className="text-black mb-2 mt-4 font-size15">Vagas</h2>
                <p>{data.eventos.vagas}</p>
                <h2 className="text-black mb-2 mt-4 font-size15">Certificação</h2>
                <p>{data.eventos.certificacao}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mt-4">
            <h2 className="text-black mb-2 font-size15">Contato</h2>
            {contato && renderRichText(contato, options)}
          </div>
          {data.eventos.inserirFormulario &&
          <>
            <div className="col-lg-4 mt-4">
              <h2 className="text-black mb-2">Inscreva-se</h2>
            </div>
            <div className="col-lg-12">
              <form className="w-100 card pt-3 pb-3 form-spani bg-gray border-0">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 mb-2">
                      <label htmlFor="nome" className="mb-0 mt-0">Nome Completo*</label>
                      <input type="text" id="nome" className="w-100" name="nome" onChange={(e) => setInput(e)} value={formState.nome}/>
                    </div>
                    <div className="col-lg-6 mb-2">
                      <label htmlFor="email" className="mb-0 mt-2">E-mail*</label>
                      <input type="text" id="email" className="w-100" name="email" onChange={(e) => setInput(e)} value={formState.email}/>
                    </div>
                    <div className="col-lg-5 mb-2">
                      <label htmlFor="telefone" className="mb-0 mt-2">Telefone*</label>
                      <InputMask mask={[/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} maskPlaceholder={null} type="text" id="telefone" className="w-100" name="telefone" value={formState.telefone} onChange={(e) => {
                        setInput(e)
                      }} />
                    </div>
                    <div className="col-lg-5 mb-2">
                      <label htmlFor="curso" className="mb-0 mt-2">Curso Desejado*</label>
                      <select type="text" id="curso" name="curso" onChange={(e) => setInput(e)} value={formState.curso} className="w-100">
                        <option value={data.eventos.nome} selected key="0">{data.eventos.nome}</option>
                        {
                          _cursos.map((loja, index) => {
                            return (
                              <option value={loja.node.nome} key={index}>
                                {loja.node.nome}
                              </option>
                            )
                          })
                        }
                      </select>
                    </div>
                    <div className="col-lg-2 mb-2 d-flex align-items-end pl-lg-0">
                      <button className="btn btn-blue d-flex w-100 text-uppercase justify-content-center font-openSans-extrabold custom-heightbutton align-items-center" disabled={clickEnvio === true} onClick={(e) => (enviarMensagem(e))}>Inscrever</button>
                    </div>
                    {
                      !!errorMessage && (
                        <div className="col-lg-12">
                          <div className="d-flex bg-danger align-items-center justify-content-center py-2 px-4 my-1 rounded">
                            <p className="shadow rounded-circle text-white text-center align-self-center mb-0 mr-3"
                              style={{ width: '25px', height: '25px' }} >!</p>
                            <p className="text-center text-white mb-0 font-weight-bold w-75 fonterror">
                              {errorMessage}
                            </p>
                          </div>
                        </div>
                      )
                    }
                    {
                      !!successMessage && (
                        <div className="col-md-12">
                          <div className="d-flex align-items-center justify-content-center bg-success rounded p-2 my-3">
                            <p
                              className="border text-white rounded-circle mb-0 mr-3 font-size-15 d-flex justify-content-center align-items-center"
                              style={{ width: '45px', height: '45px' }} >✔</p>
                            <p className="font-weight-bold text-white text-center font-size-1125 m-0">
                              {successMessage}
                            </p>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </form>
            </div>
          </>
          }
        </div>
      </div>
      <SectionCursos path={data.eventos.slug} />
    </Layout>
  );
}

export default templateCursos

export const query = graphql`query ($slugId: String!) {
  eventos: contentfulSpaniQualifica(id: {eq: $slugId}) {
    contato {
      raw
    }
    nome
    slug
    dataInicio
    dataFim
    certificacao
    localizacao
    cargaHoraria
    inserirFormulario
    preRequisitos {
      internal {
        content
      }
    }
    card {
      localFile {
        publicURL
      }
    }
    imagemInterna {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    vagas
    valor
    sobre {
      raw
    }
  }
  todosEventos: allContentfulSpaniQualifica(
    sort: {fields: dataInicio, order: ASC}
  ) {
    edges {
      node {
        slug
        nome
        dataInicio
      }
    }
  }
  pagina: contentfulPagina(path: {eq: "/lgpd/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    pageSummary
    tags
    possuiBanner
    card {
      localFile {
        publicURL
      }
    }
    desktop {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    mobile {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 414, height: 310, layout: CONSTRAINED)
        }
      }
    }
  }
}
`
